import React from 'react';
import { motion } from 'framer-motion';

const Loader = () => {
  const letters = "MonSaaS".split("");

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white">
      <motion.div
        className="flex space-x-1 text-4xl font-extrabold mb-4"
      >
        {letters.map((letter, index) => (
          <motion.span
            key={index}
            initial={{ color: '#4CAF50' }}
            animate={{
              color: ['#4CAF50', '#2E7D32', '#66BB6A'],
            }}
            transition={{
              duration: 1.5,
              ease: 'easeInOut',
              repeat: Infinity,
              repeatType: 'mirror',
              delay: index * 0.2,
            }}
          >
            {letter}
          </motion.span>
        ))}
      </motion.div>
      <motion.div
        className="loader"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, ease: 'easeInOut', loop: Infinity }}
      >
        <svg
          className="animate-spin h-12 w-12 text-[#4CAF50]"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8v8H4z"
          ></path>
        </svg>
      </motion.div>
    </div>
  );
};

export default Loader;
