import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Loader from '../components/Loader';

// Lazy load components without delay
const VitrineNav = lazy(() => import('./vitrine'));
const AuthNav = lazy(() => import('./auth'));

function MainNavigation() {
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Routes>
          {/* Route pour le contenu de la vitrine */}
          <Route path="/*" element={<VitrineNav />} />

          {/* Route pour l'authentification */}
          <Route path="/auth/*" element={<AuthNav />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default MainNavigation;


//================================================================================//
// import React, { Suspense, lazy } from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Loader from '../components/Loader';

// // Fonction pour simuler un délai de chargement
// function simulateDelay(ms) {
//   return new Promise(resolve => setTimeout(resolve, ms));
// }

// // Lazy load components with delay simulation
// const VitrineNav = lazy(() =>
//   simulateDelay(3000).then(() => import('./vitrine'))
// );
// const AuthNav = lazy(() =>
//   simulateDelay(2000).then(() => import('./auth'))
// );

// function MainNavigation() {
//   return (
//     <Router>
//       <Suspense fallback={<Loader />}>
//         <Routes>
//           {/* Route pour le contenu de la vitrine */}
//           <Route path="/*" element={<VitrineNav />} />

//           {/* Route pour l'authentification */}
//           <Route path="/auth/*" element={<AuthNav />} />
//         </Routes>
//       </Suspense>
//     </Router>
//   );
// }

// export default MainNavigation;
